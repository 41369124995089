// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

function renderApp() {
    import('./App')
        .then(({ default: App }) => {
            ReactDOM.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>,
                root
            );
        })
        .catch(err => {
            console.error('🔥 App load failed:', err);

            // Broad catch for any failure (ChunkLoadError or 404 on chunk)
            if (
                err.name === 'ChunkLoadError' ||
                /Loading chunk [\d]+ failed/.test(err.message) ||
                err.message?.includes('failed to fetch') ||
                err.message?.includes('404')
            ) {
                console.warn('⚠️ New build detected or missing chunk. Clearing cache and reloading...');

                if ('caches' in window) {
                    caches.keys().then(names => {
                        names.forEach(name => caches.delete(name));
                    });
                }

                window.location.reload(true);
            } else {
                throw err;
            }
        });
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
