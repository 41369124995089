import React from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const StyledButton = withStyles({
    root: {
        margin: '5px',
        '&.correct': {
            backgroundColor: '#259F00',
            color: 'white',
            '&:hover': {
                // backgroundColor: 'darkgreen',
            },
        },
        '&.incorrect': {
            backgroundColor: '#C33000',
            color: 'white',
            '&:hover': {
                // backgroundColor: 'darkred',
            },
        },
        '&.selected': {
            border: '8px solid',
            borderColor: '#E19FFF',
            boxShadow: '0 0 15px #E19FFF',

        }
    }
})(Button);

class SelectableOption extends React.Component {
    render() {
        const {
            text,
            onClick,
            disabled,
            isSelected,
            isCorrect,
            isIncorrect,
            displaySleeves,
            sleevesText
        } = this.props;
        let className = '';

        if (isSelected && isCorrect) {
            className = 'correct selected';
        } else if (isSelected && isIncorrect) {
            className = 'incorrect selected';
        } else if (isCorrect) {
            className = 'correct';
        }

        // Convert `\n` to <br />
        const formattedText = text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledButton
                    size="large"
                    variant="contained"
                    onClick={onClick}
                    disabled={disabled}
                    className={className}
                >
                    {/*{text}*/}
                    <span style={{ textAlign: 'center' }}>{formattedText}</span>
                </StyledButton>
                {displaySleeves && (
                    <div style={{ marginLeft: '10px', color: isCorrect ? 'green' : 'red', fontFamily: "Roboto", fontWeight: 'bold', fontSize: '1.2em' }}>
                        {sleevesText}
                    </div>
                )}
            </div>
        );
        }
}

export default SelectableOption;
